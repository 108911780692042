.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #EBE5E5;
    text-align: center;
  }

  .header-container {
    height: 25vh;
    align-content: center;

  }
  
  .header {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 100;
  }
  
  .subheader {
    font-family: 'Inter', sans-serif;
    font-size: 28px;
    font-style: italic;
    font-weight: 100;
    margin-left: 60px;
  }

  .download-button {
    position: absolute;
    top: 40px;
    right: 40px;
    background: transparent;
    border: 1px solid black;
    border-radius: 23px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 20px 15px 20px;
    color: black
  }
  
  .download-text {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
  }
  
  .price-text {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 300;
  }
  
  .modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    border-radius: 10px;
    text-align: center;
  }

  .modal-header {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: italic;
    font-weight: 300;
    margin: 50px;
  }
  
  .modal-body {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 75px;
    margin-right: 50px;
    margin-left: 50px;
    text-align: left;
  }
  
  .modal-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 45px;
  }
  
  .modal-input {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 23px;
    margin-right: 25px;
    flex: 1;
  }
  
  .modal-button {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 23px;
    cursor: pointer;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .split-container {
    display: flex;
    height: 100%;
    width: calc(100% - 70px);
    border-radius: 25px;
    overflow: hidden;
    margin-bottom: 50px;
  }
  
  .left-half {
    background-color: white;
    flex: 1;
    padding: 20px 20px 20px 50px;;
    text-align: left;

  }

  .mini-header-text {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 300;
    margin: 50px 0px 10px 0px;
  }
  .left-body-text {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 100;
    margin-right: 75px;
  }
  
  .left-header {
    font-family: 'Inter', sans-serif;
    font-size: 64px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 0px;
    margin-right: 50px;
  }

  .download-button-card{
    background: transparent;
    border: 1px solid black;
    border-radius: 23px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 20px 15px 20px;
    color: black;
    }

  .left-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
    margin-top: 50px;
  }
  
  
  .right-half {
    background-color: #F3F3F3;
    flex: 1;
    align-content: center;
  }

  .slider-image1 {
    max-width: 40%;
    max-height: 90%;
    margin: 15px 15px 45px 15px; 
  }

  .slider-image2 {
    max-width: 40%;
    max-height: 90%;
    margin: 10px 15px 15px 15px;
  }

  .copyright{
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
  }

  @media(max-width: 1233px){
    .left-header {
      font-size: 40px;
    }
    .mini-header-text {
      font-size: 16px;
    }
    .left-row{
      flex-direction: column;
      align-items: flex-start;
      margin-top: 30px;
    }
    .download-button-card{
      margin-top: 25px;
    }
  }

  @media(max-width: 1119px){
    .download-button-card{
      margin-bottom: 25px;
    }
    .mini-header-text{
      margin-top: 30px;
    }
  }

  @media (max-width: 973px){
    .split-container{
      flex-direction: column;
      width: 75%;
    }
    .left-half{
      border-radius: 23px;
      margin-bottom: 40px;
    }
    .right-half{
      border-radius: 23px;
    }
    .left-row{
      align-items: flex-end;
      margin-top: 20px;
      margin-right: 20px;
    }
  }
@media(max-width: 649px){
  .mini-header-text{
    font-size: 12px;
  }
  .header-container{
    display: flex;
    flex-direction: column;
  }
  .download-button{
    margin-top: 40px;
    position: relative;
    top:0px;
    right:0px;
    width: 129px;
    margin-left: 57.5px;
  }
  .header-container{
    height: 35vh
  }

}

@media(max-width: 578px){
  .left-header{
    font-size: 32px;
  }
  .download-button-card{
    display:none
  }
  .mini-header-text{
    margin-top: 15px
  }
  .header{
    margin-top:20px;
  }
  .left-body-text{
    margin: 0px 0px 20px 0px;
  }
  .modal-input-container{
    flex-direction: column;
    margin:30px 20px 30px 20px;
  }
  .modal-input{
    margin: 0px 20px 20px 20px;
    width: 100%;
  }
  .modal-body{
    margin: 0px 10px 0px 10px;
  }
}
@media(max-width: 509px){
  .mini-header-text{
    font-size: 10px
  }
  .left-body-text{
    font-size: 16px
  }
}

@media(max-width: 468px){
  .left-header{
    font-size: 24px;
  }
  .left-body-text{
    font-size: 14px;
  }
  .mini-header-text{
    font-size: 8px;
  }
}
@media(max-width: 414px){
  .left-header{
    font-size: 22px;
  }
  .left-body-text{
    font-size: 12px;
  }
  .slider-image1{
    max-width: 80%;
    margin-bottom: 15px
  }
  .slider-image2{
    max-width: 80%;
  }
  .left-half{
    padding-left: 30px
  }
}